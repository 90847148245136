import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Square,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  ListItem,
  VStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { TapPayField, TapPayLabel } from "@/components/TapPay";
import creditCardPlaceholder from "@/assets/images/credit-card-placeholder.png";
import MasterCard from "@/assets/images/payment_methods/MasterCard.png";
import VISA from "@/assets/images/payment_methods/VISA.png";
import JCB from "@/assets/images/payment_methods/JCB.png";
import { useCallback, useEffect, useRef, useState } from "react";
import useAPI from "@/libs/hooks/api";
import OrderConfirm from "../OrderConfirm";
import useTappay from "@/libs/hooks/tappay";
import gtmInitiateCheckout from "@/libs/gtm-initiate-checkout";

const CARD_SCHEMES = {
  MasterCard,
  VISA,
  JCB,
};

const CustomTab = (props) => (
  <Tab
    color="white"
    _selected={{ color: "white", borderColor: "secondary.100" }}
    {...props}
  />
);

const CreditCardView = ({ active, details, onCancel }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [tp, ready] = useTappay();
  const api = useAPI();

  const cardNumberRef = useRef(null);
  const expDateRef = useRef(null);
  const ccvRef = useRef(null);

  const [confirming, setConfirming] = useBoolean(false);
  const [cards, setCards] = useState(null);
  const [valid, setValid] = useState(false);
  const [holder, setHolder] = useState("");
  const [cardName, setCardName] = useState("");
  const [save, setSave] = useState(false);
  const [cardId, setCardId] = useState(null);

  const forbidden = !cardId && (!valid || !holder || (save && !cardName));

  const reset = useCallback(() => {
    setCardId(null);
    setCardName(null);
    setSave(false);
  }, []);

  const cancel = useCallback(() => {
    setConfirming.off();
    reset();
    onCancel();
  }, [reset, onCancel, setConfirming]);

  const onConfirm = useCallback(async () => {
    const paymentInfo = { holder, saveCard: save };
    if (!cardId) {
      // pay by prime
      const result = await new Promise((resolve) => tp.card.getPrime(resolve));
      const { card } = result;
      paymentInfo.prime = card.prime;
      if (paymentInfo.saveCard) paymentInfo.name = cardName;
    } else {
      paymentInfo.cardId = cardId;
    }
    const { cost = 0, type, plan } = details || {};
    const apiCall = () => {
      switch (type) {
        case "subscribe":
          return api.vip({
            plan,
            ...paymentInfo,
          });
        case "deposit":
        default:
          return api.deposit({
            price: cost,
            ...paymentInfo,
          });
      }
    };
    const amount = cost || plan?.cost - (plan?.discount || 0);
    gtmInitiateCheckout(amount, "Credit Card");

    const { paymentUrl } = await apiCall();
    // redirect to target
    if (paymentUrl) window.location.href = paymentUrl;
    else {
      toast({ status: "error", title: t("profile.payment.failed") });
      onCancel();
    }
  }, [holder, save, cardId, details, cardName, tp, api, toast, t, onCancel]);

  useEffect(() => {
    if (!ready) return;
    // setup credit card
    const fields = {
      number: {
        element: cardNumberRef.current,
        placeholder: "**** **** **** ****",
      },
      expirationDate: {
        element: expDateRef.current,
        placeholder: "MM / YY",
      },
      ccv: {
        element: ccvRef.current,
        placeholder: "CCV",
      },
    };
    tp.card.setup({
      fields: fields,
    });
    tp.card.onUpdate((update) => {
      if (update.canGetPrime) {
        setValid(true);
      } else {
        setValid(false);
      }
    });
  }, [tp, ready]);

  // get saved cards
  useEffect(() => {
    api.getSavedCards().then(setCards);
  }, [api]);

  return (
    <Box hidden={!active}>
      <Tabs isFitted>
        <TabList>
          <CustomTab>{t("profile.payment.add_card")}</CustomTab>
          <CustomTab>{t("profile.payment.select_card")}</CustomTab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack alignItems="stretch" gap={6} color="#D9D9D9" mt={4}>
              <Flex align="center" gap={2}>
                <TapPayLabel>{t("profile.payment.card_owner")}</TapPayLabel>
                <Input
                  value={holder}
                  onChange={(e) => setHolder(e.target.value)}
                  bg="white"
                  height={8}
                  px={2}
                  flex={1}
                  fontSize="0.875rem"
                  fontWeight="bold"
                  borderRadius={2}
                  placeholder="ooooo"
                  color="gray.800"
                />
              </Flex>
              <Flex align="center" gap={2}>
                <TapPayLabel>{t("profile.payment.card_number")}</TapPayLabel>
                <TapPayField ref={cardNumberRef} />
              </Flex>
              <Flex gap={2} align="center">
                <TapPayLabel>{t("profile.payment.card_exp")}</TapPayLabel>
                <TapPayField ref={expDateRef} />
                <TapPayLabel>{t("profile.payment.card_ccv")}</TapPayLabel>
                <TapPayField ref={ccvRef} />
              </Flex>
              <Checkbox
                value={save}
                onChange={(e) => setSave(e.target.checked)}
              >
                {t("profile.payment.save_card")}
              </Checkbox>
              {save && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb={0}>{t("profile.payment.card_name")}</FormLabel>
                  <Input
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    color="#1D1D1D"
                    bg="white"
                    size="sm"
                    flex={1}
                  />
                </FormControl>
              )}
            </VStack>
          </TabPanel>
          <TabPanel>
            {cards?.length === 0 && (
              <VStack align="center" pt={5} color="white">
                <Square size={36}>
                  <Image src={creditCardPlaceholder} />
                </Square>
                <Text mt={3}>{t("profile.payment.no_saved_cards")}</Text>
              </VStack>
            )}

            <VStack gap={3} align="stretch">
              {cards?.map(({ id, name, scheme, lastFour, binCode }) => (
                <Box color="white" key={id}>
                  <Text fontSize="sm" pb={2}>
                    {name}
                  </Text>
                  <Flex
                    role="button"
                    borderColor={cardId === id ? "#F9A000" : "#8E7046"}
                    borderWidth={2}
                    borderRadius={12}
                    justify="space-between"
                    align="center"
                    position="relative"
                    px={7}
                    py={5}
                    onClick={() => setCardId(id)}
                  >
                    <Image src={CARD_SCHEMES[scheme]} width={8} />
                    <Text flex={1} align="center">
                      {binCode?.slice(0, 4) || "****"}-****-****-{lastFour}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </VStack>
          </TabPanel>
          <VStack px={4} gap={4} align="stretch">
            <Box fontSize="xs" color="white">
              <Text>{t("profile.payment.card_disclaimer")}</Text>
              <UnorderedList>
                <ListItem>{t("profile.payment.card_disclaimer_1")}</ListItem>
                <ListItem>
                  <Trans
                    i18nKey="profile.payment.card_disclaimer_2"
                    components={{
                      link1: (
                        <Box
                          as="a"
                          color="red.400"
                          href="https://www.lourdes.org.tw/Default.aspx"
                          rel="noopener noreferrer"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </ListItem>
              </UnorderedList>
            </Box>

            <Flex gap={3}>
              <Button
                onClick={cancel}
                colorScheme="whiteAlpha"
                borderRadius={20}
                flex={1}
              >
                {t("common.actions.cancel")}
              </Button>

              <Button
                onClick={setConfirming.on}
                variant="themed"
                isDisabled={forbidden}
                flex={1}
              >
                {t("common.actions.confirm")}
              </Button>
            </Flex>
          </VStack>
        </TabPanels>
      </Tabs>
      {details && (
        <OrderConfirm
          {...details}
          isOpen={confirming}
          onClose={cancel}
          onCancel={cancel}
          onConfirm={onConfirm}
        />
      )}
    </Box>
  );
};

export default CreditCardView;
