import TagManager from "react-gtm-module";

const gtmInitiateCheckout = (amount, method) => {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event: "add_payment_info",
      ecommerce: {
        payment_type: method,
      },
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event: `InitiateCheckout_${amount}`,
    },
  });
};
export default gtmInitiateCheckout;
