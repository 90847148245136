import "swiper/css";

import Playlist from "@/components/Playlist";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PlaylistContext from "@/context/playlist";
import AuthContext from "@/context/auth";
import PersistentStorage from "@/libs/persistent-storage";
import videosTour from "@/components/UserTour/configs/videos-tour";
import UserTour from "@/components/UserTour";
import useAPI from "@/libs/hooks/api";
import { Box, Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { FETCHING_STATUS } from "@/constants";
import Chatroom from "@/components/Chatroom";
import ChatContext from "@/context/chat";
import useDeviceInfo from "@/libs/hooks/device-info";

const CHAT_DRAWER_PARAMS_KEY = "chat.creator";

const PlaylistPage = () => {
  const { id } = useParams();
  const api = useAPI();
  const { isMobile } = useDeviceInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, authLoaded } = useContext(AuthContext);
  const { chats } = useContext(ChatContext);
  const { status, setStatus, setVideos, setImmersive } =
    useContext(PlaylistContext);
  const [done, setDone] = useState(false);
  const [startId, setStartId] = useState(id);
  const [creatorId, setCreatorId] = useState(null);
  const runTour =
    user &&
    user.newbie &&
    PersistentStorage.getItem(videosTour.key) !== "visited";

  const chatroomId = useMemo(() => {
    const chatEntries = Object.entries(chats);
    const entry = chatEntries.find(
      ([_, { creator }]) => creator.id === creatorId,
    );
    return entry?.[0];
  }, [chats, creatorId]);

  const chatOpen = useMemo(
    () => searchParams.has(CHAT_DRAWER_PARAMS_KEY),
    [searchParams],
  );

  const onCreatorClick = useCallback(
    (creatorId) => navigate(`/c/${creatorId}`),
    [navigate],
  );
  const onChatClick = useCallback(
    (id) => {
      setCreatorId(id);
      setSearchParams({ [CHAT_DRAWER_PARAMS_KEY]: id }, { shallow: true });
    },
    [setSearchParams],
  );

  const closeChatPreview = useCallback(() => {
    setCreatorId(null);
    setSearchParams({}, { shallow: true });
  }, [setSearchParams]);

  const changeUrl = useCallback(
    (id) => {
      const { pathname } = location;
      if (pathname !== "/" && !pathname.startsWith("/videos/")) return;
      navigate(`/videos/${id}`, { shallow: true });
    },
    [navigate, location],
  );

  const loader = useCallback(async () => {
    if (!authLoaded) return;
    if (done) return;
    const source = user ? api.getVideosFeed : api.getGuestVideos;

    const newVideos = [];
    if (status === FETCHING_STATUS.FETCHING) return;
    setStatus(FETCHING_STATUS.FETCHING);
    const { data, paginator } = await source(startId);

    if (Array.isArray(data)) newVideos.push(...data);
    else {
      /* @todo: handle fetch error */
    }
    if (paginator?.next === null || newVideos.length === 0) setDone(true);

    setVideos((state) => state.concat(newVideos));
    if (startId) setStartId(null);
    setStatus(FETCHING_STATUS.FINISHED);

    return newVideos[0]?.id;
  }, [authLoaded, done, user, api, status, setStatus, startId, setVideos]);

  useEffect(() => {
    if (isMobile) {
      setImmersive(searchParams.has(CHAT_DRAWER_PARAMS_KEY));
    }
  }, [isMobile, searchParams, setImmersive]);

  return (
    <Box position="absolute" top={0} left={{ base: 0, md: 320 }} right={0}>
      <Playlist
        onChange={changeUrl}
        onCreatorClick={onCreatorClick}
        onChatClick={onChatClick}
        loader={loader}
        done={done}
      />
      <UserTour
        start={runTour}
        config={videosTour}
        onComplete={api.claimNewbieBooster}
      />

      <Drawer
        isOpen={chatOpen}
        onClose={closeChatPreview}
        placement="right"
        size="sm"
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent bg="primary.100">
          <Chatroom
            id={chatroomId}
            creatorId={creatorId}
            onBack={closeChatPreview}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default PlaylistPage;
