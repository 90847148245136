import { Flex, Text, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Currency from "./Currency";
import { useCallback } from "react";

const DepositPlan = ({ cost, points, discount, onSelect, vipOnly, rate }) => {
  const { t } = useTranslation();
  const fixedNumber = useCallback(
    (n) => (rate !== 1 ? (n * rate).toFixed(2) : n),
    [rate],
  );

  return (
    <Flex
      role="button"
      onClick={onSelect}
      borderColor="#8E7046"
      borderWidth={2}
      borderRadius={12}
      justify="space-between"
      align="center"
      position="relative"
      px={4}
      py={6}
    >
      <Flex align="center" gap={2}>
        <Currency size={6} />
        <Text>
          {points} {t("common.currency")}<br/>
          <Text as={'span'} fontSize={'12px'}>{cost - discount === 999 && "（加贈周杰倫演唱會門票抽獎資格）"}</Text>
        </Text>
      </Flex>

      <Box textAlign="right" position="relative">
        <Text pt={discount ? 3 : 0}>$ {fixedNumber(cost - discount)}</Text>
        {!!discount && (
          <Text
            position="absolute"
            right={0}
            bottom={-4}
            textDecor="line-through"
            textDecorationColor="red"
            fontSize="sm"
          >
            $ {fixedNumber(cost)}
          </Text>
        )}
      </Box>
      {!!discount && (
        <Flex
          position="absolute"
          right={-4}
          top={-2}
          px={3}
          rounded="xl"
          align="center"
        >
          {vipOnly && (
            <Box pl={3} pr={7} mr={-6} rounded="xl" bg="#C93434" pb={1}>
              VIP 限定
            </Box>
          )}

          <Box
            px={3}
            pb={1}
            rounded="xl"
            bg="linear-gradient(180deg, #F7C74E 0%, #F28C32 100%)"
            color="black"
          >
            Sale
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default DepositPlan;
