import getPublicUrl from "@/libs/get-public-url";
import {
  Avatar,
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import Currency from "../Currency";
import { useContext, useMemo } from "react";
import AuthContext from "@/context/auth";

const CustomTab = (props) => (
  <Tab
    color="white"
    bg="#26221D"
    _selected={{ color: "secondary.100", borderColor: "secondary.100" }}
    _first={{ borderTopLeftRadius: 10 }}
    _last={{ borderTopRightRadius: 10 }}
    {...props}
  />
);

const createJoeDoe = (index) => ({
  id: `joe-doe-${index}`,
  displayName: "--",
  picture: "",
  total: "--",
});

const DonatorList = ({ donators, userDonate }) => {
  const { user } = useContext(AuthContext);

  const ranks = useMemo(
    () =>
      Array.from(
        { length: 10 },
        (_, index) => donators[index] || createJoeDoe(index),
      ),
    [donators],
  );
  const [first, second, third, ...rest] = ranks;

  const userRank = ranks.findIndex((donator) => donator.id === user.id) + 1;

  return (
    <Box
      bg="#2E2D2B"
      shadow="md"
      borderTopRadius={16}
      maxH={{ base: "50vh", md: "none" }}
      overflowY="auto"
    >
      <Tabs isFitted>
        <TabList borderTopRadius={16} position="sticky" top={0} zIndex={1}>
          <CustomTab>排行榜</CustomTab>
        </TabList>
        <TabPanels>
          <TabPanel px={0} pb={0}>
            <Box position="relative" px={8}>
              <Flex align="center" justify="center" gap={8} pb={6}>
                <VStack align="center" color="white" gap={1}>
                  <Avatar src={getPublicUrl(second.picture)} size="lg" />
                  <Text color="white">{second.displayName}</Text>
                  <Flex align="center" gap={1}>
                    <Currency size={4} mt={1.5} />
                    <Text>{second.total}</Text>
                  </Flex>
                </VStack>

                <VStack align="center" color="white" gap={1}>
                  <Avatar src={getPublicUrl(first.picture)} size="xl" />
                  <Text color="white">{first.displayName}</Text>
                  <Flex align="center" gap={1}>
                    <Currency size={4} mt={1.5} />
                    <Text>{first.total}</Text>
                  </Flex>
                </VStack>

                <VStack align="center" color="white" gap={1}>
                  <Avatar src={getPublicUrl(third.picture)} size="lg" />
                  <Text color="white">{third.displayName}</Text>
                  <Flex align="center" gap={1}>
                    <Currency size={4} mt={1.5} />
                    <Text>{third.total}</Text>
                  </Flex>
                </VStack>
              </Flex>
              <VStack align="stretch">
                {rest.map((donator, index) => (
                  <Flex key={donator.id} align="center" gap={2} color="white">
                    <Text color="white" width={6} align="center">
                      {index + 4}
                    </Text>
                    <Avatar src={getPublicUrl(donator.picture)} size="sm" />
                    <Text flex={1}>{donator.displayName}</Text>
                    <Flex align="center" gap={1}>
                      <Currency size={4} mt={1.5} />
                      <Text>{donator.total}</Text>
                    </Flex>
                  </Flex>
                ))}
              </VStack>
            </Box>
            <Box
              position="sticky"
              bottom={0}
              right={0}
              left={0}
              height={12}
              borderTopRadius={16}
              width="100%"
              bg={
                userRank === 0
                  ? "linear-gradient(90deg, #636363 0%, #333 100%)"
                  : "linear-gradient(90deg, #D69040 0%, #A85832 100%)"
              }
            >
              <Flex
                key={user.id}
                align="center"
                gap={2}
                color="white"
                height={12}
                px={8}
              >
                <Text color="white" width={6} align="center">
                  {userRank || "--"}
                </Text>
                <Avatar src={getPublicUrl(user.picture)} size="sm" />
                <Text flex={1}>{user.displayName}</Text>
                <Flex align="center" gap={1}>
                  <Currency size={4} mt={1.5} />
                  <Text>{userDonate}</Text>
                </Flex>
              </Flex>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DonatorList;
