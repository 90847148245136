import useDeviceInfo from "@/libs/hooks/device-info";
import { memo, useMemo } from "react";
import { Replay } from "@nightco/replay";
import ShakaVideoStreamer from "@nightco/replay/video-streamer/shaka-player";
import HlsjsVideoStreamer from "@nightco/replay/video-streamer/hlsjs";

const propsComparator = (prevProps, nextProps) =>
  prevProps.playbacks === nextProps.playbacks &&
  prevProps.src === nextProps.src &&
  prevProps.active === nextProps.active &&
  prevProps.paused === nextProps.paused &&
  prevProps.handleStateChange === nextProps.handleStateChange;

const VideoPlayer = memo(
  ({
    src = null,
    playbacks = [],
    stream = false,
    active,
    muted,
    paused,
    handleStateChange,
    setControls,
  }) => {
    // const { isSafari, isIPhone } = useDeviceInfo();

    const Streamer = stream ? HlsjsVideoStreamer : ShakaVideoStreamer;

    // const playback = isSafari || isIPhone ? playbacks?.[1] : playbacks?.[0];
    const playback = playbacks?.[0];

    const source = useMemo(() => {
      if (!active || (!src && !playback)) return null;
      if (src) return src;

      return {
        ...playback,
        // licenseAcquisitionDetails: {
        //   licenseRequestHeaders: {
        //     "X-Axdrm-Message": playback?.drmToken,
        //   },
        //   fairPlayCertificateUrl:
        //     "https://assets.nightco.io/FPSCert/fairplay.cer",
        // },
        configuration: {},
      };
    }, [playback, src, active]);

    return (
      <Replay
        source={source}
        autoplay
        initialPlaybackProps={{ isMuted: muted, isPaused: paused }}
        onStreamStateChange={handleStateChange}
        onPlaybackActionsReady={setControls}
      >
        <Streamer />
      </Replay>
    );
  },
  propsComparator,
);

export default VideoPlayer;
