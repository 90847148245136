import MobileNavigation from "@/components/MobileNavigation";
import Stream from "@/components/Stream";
import StreamContext from "@/context/stream";
import useAPI from "@/libs/hooks/api";
import useDeviceInfo from "@/libs/hooks/device-info";
import usePolyfills from "@/libs/hooks/polyfills";
import { Box, Image, Square, Text, VStack } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Mousewheel, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import streamPlaceholder from "@/assets/images/stream-placeholder.png";
import { useNavigate, useParams } from "react-router-dom";

const StreamsPage = () => {
  const { height, isMobile } = useDeviceInfo();
  const { id } = useParams();
  const api = useAPI();
  const navigate = useNavigate();
  const { expanding, setNotice } = useContext(StreamContext);
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const [streams, setStreams] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [active, setActive] = useState(0);

  const updateUrl = useCallback(
    (streamId) =>
      navigate(`/streams/${streamId}`, { replace: true, shallow: true }),
    [navigate],
  );

  const onRefresh = useCallback(
    async (id) => {
      const clone = streams.slice();
      const index = clone.findIndex((s) => s.id === id);
      if (index === -1) return;
      const { stream } = await api.getStream(id);
      clone[index] = stream;
      setStreams(clone);
    },
    [api, streams],
  );

  const onSlideChange = useCallback(
    ({ activeIndex }) => {
      setActive(activeIndex);
      const activeId = streams[activeIndex]?.id;
      if (activeId) updateUrl(activeId);
    },
    [updateUrl, streams],
  );

  useEffect(() => {
    async function fetchStreams() {
      const streams = await api.getStreams();
      setStreams(streams);
      if (swiper) {
        const index = id ? streams.findIndex((s) => s.id === id) : 0;
        if (index === -1) return;
        if (index === 0) {
          const streamId = streams[index]?.id;
          updateUrl(streamId);
        }
        swiper.slideTo(index);
      }
    }
    setNotice(false);
    fetchStreams();
    // intentionally omit id to avoid unnecessary re-fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, setNotice, swiper, updateUrl]);

  return (
    <Box
      height={{
        base: expanding
          ? fullscreen
          : `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
        md: fullscreen,
      }}
      overflow="hidden"
      bg="primary.100"
    >
      {streams?.length ? (
        <Swiper
          direction="vertical"
          height={height - (expanding ? 0 : MobileNavigation.HEIGHT)}
          modules={[Mousewheel, Virtual]}
          slidesPerView={1}
          onSwiper={setSwiper}
          onSlideChange={onSlideChange}
          allowTouchMove={isMobile}
          touchReleaseOnEdges={true}
          threshold={0}
          resistanceRatio={0.5}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 0,
            thresholdDelta: 30,
            thresholdTime: 70,
          }}
          virtual={{
            addSlidesBefore: 2,
            addSlidesAfter: 2,
          }}
        >
          {streams.map((stream, index) => (
            <SwiperSlide key={`${stream.id}-${index}`} virtualIndex={index}>
              <Stream
                {...stream}
                onRefresh={onRefresh}
                active={active === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <VStack align="center" justify="center" h="100%">
          <Square size={36}>
            <Image src={streamPlaceholder} />
          </Square>
          <Text mt={3} color="white" align="center">
            場館整理中 <br />
            請等候開播通知
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default StreamsPage;
