import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Collapse,
  Flex,
  Square,
  Text,
  useBoolean,
  useDisclosure,
  useOutsideClick,
  useToast,
  VStack,
} from "@chakra-ui/react";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import useDeviceInfo from "@/libs/hooks/device-info";
import getPublicUrl from "@/libs/get-public-url";
import useCrisp from "@/libs/hooks/crisp";

import FAIcon from "../FAIcon";
import Currency from "../Currency";
import MobileNavigation from "../MobileNavigation";
import VideoPlayer from "../VideoPost/VideoPlayer";
import useAPI from "@/libs/hooks/api";
import VideoOverlay from "../VideoPost/VideoOverlay";
import Chat from "./Chat";
import UserOverridesContext from "@/context/user-overrides";
import Pill from "../Pill";
import ShareModal from "../ShareModal";
import InputArea from "./InputArea";
import StreamContext from "@/context/stream";
import DonatorList from "./DonatorList";
import PinnedList from "./PinnedList";

const IconButton = ({ icon, variant = "regular", ...props }) => (
  <Square role="button" size="1.25em" {...props}>
    <FAIcon type={icon} variant={variant} color="white" />
  </Square>
);

const Live = ({ id, creator, streamUrl, viewers, active, income }) => {
  const { t } = useTranslation();
  const api = useAPI();
  const toast = useToast();
  const { width, height } = useDeviceInfo();
  const crisp = useCrisp();
  const { immersive, setImmersive, action } = useContext(StreamContext);
  const { overrides, overrideCreator } = useContext(UserOverridesContext);
  const donatorListRef = useRef(null);
  const {
    isOpen: isDonatorListOpen,
    onOpen: openDonatorList,
    onClose: closeDonatorList,
  } = useDisclosure();

  const [donators, setDonators] = useState([]);
  const [userDonate, setUserDonate] = useState(0);
  const [shareData, setShareData] = useState(null);
  const [muted, setMuted] = useBoolean(true);
  const [paused, setPaused] = useBoolean(false);
  const [controls, setControls] = useState({
    setProperties: () => null,
  });

  const followed = useMemo(() => {
    if (!creator) return false;
    const override = overrides.creators[creator.id];
    return override?.isFollowed ?? creator.isFollowed;
  }, [creator, overrides.creators]);

  const sendText = useCallback(
    async (message) =>
      await api.createStreamEvent(id, {
        type: "comment",
        data: message,
      }),
    [api, id],
  );

  const sendGift = useCallback(
    async (giftId) =>
      await api.createStreamEvent(id, { type: "gift", data: { giftId } }),
    [api, id],
  );

  const shareStream = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const { displayName, introduce } = creator;
    const data = {
      title: `來 NightCo 看${displayName}的直播 ♥️`,
      text: introduce,
      url: `https://${window.location.host}/stream/${id}`,
    };
    if (canShare) {
      try {
        await navigator.share(data);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        const isAborted = e instanceof DOMException && e.name === "AbortError";
        if (!isAborted) console.error(e);
      }
    } else {
      setShareData(data);
    }
  }, [creator, toast, id]);

  const toggleFollowed = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = followed ? api.unfollowCreator : api.followCreator;
      await action(creator.id);
      overrideCreator(creator.id)({ isFollowed: !followed });
    },
    [followed, api, creator, overrideCreator],
  );

  const toggleMuted = useCallback(() => {
    setMuted.toggle();
    controls.setProperties({ isMuted: !muted });
  }, [controls, muted, setMuted]);

  const togglePaused = useCallback(() => {
    setPaused.toggle();
    const properties = { isPaused: !paused };
    if (paused) {
      properties.isAtLiveEdge = true;
    }
    controls.setProperties(properties);
  }, [controls, paused, setPaused]);

  useOutsideClick({
    ref: donatorListRef,
    handler: closeDonatorList,
  });

  // if not active, pause the stream
  useEffect(() => {
    if (!controls) return;
    if (active) {
      controls.setProperties({ isPaused: false, isAtLiveEdge: true });
      setPaused.off();
    } else {
      controls.setProperties({ isPaused: true });
      setPaused.on();
    }
  }, [active, controls, setPaused]);

  useEffect(() => {
    if (!id) return;
    const fetchDonateInfo = async () => {
      const { data, currentUser } = await api.getDonateRanking(id);
      setDonators(data || []);
      setUserDonate(currentUser?.total || 0);
    };
    // update every 15 seconds
    const interval = setInterval(fetchDonateInfo, 15 * 1000);
    fetchDonateInfo();
    return () => clearInterval(interval);
  }, [api, id]);

  // visibility change handler
  useEffect(() => {
    const visibilityListener = () => !document.hidden && controls?.gotoLive();
    document.addEventListener("visibilitychange", visibilityListener);
    return () =>
      document.removeEventListener("visibilitychange", visibilityListener);
  }, [controls]);

  // user enter stream events
  useEffect(() => {
    if (!id) return;
    api.createStreamEvent(id, { type: "entry" });
  }, [api, id]);

  return (
    <Box
      position="relative"
      height="100%"
      aspectRatio={{
        base: width / (height - MobileNavigation.HEIGHT),
        md: 9 / 16,
      }}
      width="100%"
    >
      {/* video begin */}
      <Box
        width="100%"
        height="100%"
        overflow="hidden"
        position="relative"
        borderRadius={{ md: ".5rem" }}
      >
        {/* responsive video container */}
        <Box position="absolute" height="100%" width="200%" left="-50%">
          <VideoPlayer
            src={streamUrl}
            stream={true}
            active={true}
            muted={muted}
            paused={paused}
            setControls={setControls}
          />
        </Box>
      </Box>
      <VideoOverlay
        active={true}
        paused={paused}
        onClick={togglePaused}
        onDoubleClick={() => {}}
      />
      <Box position="absolute" top={0} left={0} width="100%">
        <Flex px={4} mt={3} align="center" justify="space-between" gap={3}>
          <Flex align="center" bg="translucent" rounded="full" gap={2} p={2}>
            <Avatar
              src={getPublicUrl(creator?.picture)}
              width={12}
              height={12}
            />
            <Box flex={1} width="5em" color="white" whiteSpace="nowrap">
              <Text fontSize="sm" overflow="hidden" textOverflow="ellipsis">
                {creator?.displayName}
              </Text>
              <Text fontSize="xs" overflow="hidden" textOverflow="ellipsis">
                @{creator?.slug}
              </Text>
            </Box>
            <Button
              rounded="full"
              bg="#CC51D7"
              color="white"
              onClick={toggleFollowed}
            >
              {followed
                ? t("creator.actions.followed")
                : t("creator.actions.follow")}
            </Button>
          </Flex>
          {!immersive && (
            <Flex align="center" gap={2}>
              <AvatarGroup
                role="button"
                size="md"
                max={2}
                spacing={-5}
                onClick={openDonatorList}
              >
                {donators.map((donator) => (
                  <Avatar
                    key={donator.id}
                    name={donator.name}
                    src={getPublicUrl(donator.picture)}
                    border="none"
                  />
                ))}
              </AvatarGroup>
            </Flex>
          )}
        </Flex>
        <Flex align="center" px={6} mt={2} gap={3} justify="space-between">
          <Flex align="center" gap={2} fontSize="sm" color="white">
            <Pill bg="translucent">
              <Currency size={4} mt={1} mr={1} />
              {income}
            </Pill>
            {/* @todo: fix viewer count */}
            {/*
              <Pill bg="translucent">
                <FAIcon type="eye" variant="regular" fontSize="lg" mr={1} />
                <Text>{viewers}</Text>
              </Pill>
            */}
          </Flex>
          {!immersive && (
            <Pill
              bg="red.500"
              fontWeight="semibold"
              fontSize="sm"
              color="white"
            >
              LIVE
            </Pill>
          )}
        </Flex>
      </Box>

      <Box position="absolute" bottom={0} left={0} width="100%">
        <Flex gap={2}>
          <Box flex={1}>
            {!immersive && (
              <>
                <Box px={4} pb={16}>
                  <PinnedList id={id} />
                </Box>
                <Box pb={action === "typing" ? 32 : 16} px={4}>
                  <Chat id={id} />
                </Box>
              </>
            )}
            <InputArea
              active={active}
              sendMessage={sendText}
              sendGift={sendGift}
            />
          </Box>
          {!!action || (
            <VStack
              justify="flex-end"
              mb={5}
              color="white"
              pr={4}
              gap={4}
              fontSize="2xl"
              position="absolute"
              right={0}
              bottom={0}
              zIndex={10}
            >
              {!immersive && (
                <>
                  <IconButton icon="headset" onClick={() => crisp.open()} />
                  <IconButton icon="share" onClick={shareStream} />
                  <IconButton
                    icon={muted ? "volume-slash" : "volume"}
                    onClick={toggleMuted}
                  />
                </>
              )}
              <IconButton
                onClick={() => setImmersive(!immersive)}
                icon={immersive ? "compress" : "expand"}
              />
            </VStack>
          )}
        </Flex>
      </Box>
      <Box
        position="absolute"
        zIndex={10}
        bottom={0}
        right={0}
        width="100%"
        ref={donatorListRef}
      >
        <Collapse in={isDonatorListOpen} animateOpacity={false}>
          <DonatorList donators={donators} userDonate={userDonate} />
        </Collapse>
      </Box>

      <ShareModal data={shareData} onClose={() => setShareData(null)} />
    </Box>
  );
};

export default Live;
