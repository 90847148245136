import { useFirebase } from "@/libs/hooks/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";

const StreamContext = createContext({ streams: [] });

export const withStreamContext = (Component) => (props) => {
  const { firestore } = useFirebase();
  const [action, setAction] = useState(null);
  const [immersive, setImmersive] = useState(false);
  const [notice, setNotice] = useState(false);

  const expanding = !!action || immersive;

  // @todo: find better way to check if there's any live stream
  useEffect(() => {
    const q = query(
      collection(firestore, `stream-status`),
      where("status", "==", "LIVE"),
    );
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      if (snapshot.docs.length > 0) setNotice(true);
    });
    return () => unsubscribe();
  }, [firestore]);

  return (
    <StreamContext.Provider
      value={{
        action,
        setAction,
        expanding,
        notice,
        setNotice,
        immersive,
        setImmersive,
      }}
    >
      <Component {...props} />
    </StreamContext.Provider>
  );
};

export default StreamContext;
