import {
  Box,
  Center,
  Collapse,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  Link,
  Button,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useOutletContext } from "react-router-dom";

import FAIcon from "@/components/FAIcon";
import SubscribePlan from "@/components/SubscribePlan";
import * as Membership from "@/components/Membership";
import { VIP_PLANS } from "@/constants";

const VIPPage = () => {
  const { currency, exchangeRates, onSelect } = useOutletContext();
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const selectPlan = useCallback(
    (plan) => () => onSelect({ ...plan, type: "subscribe" }),
    [onSelect],
  );

  return (
    <Box>
      <VStack color="white" align="stretch" gap={2}>
        <Box mb={2}>
          <Box
            bg="linear-gradient(270deg, #523B19 0%, #665235 75%)"
            p={6}
            color="white"
            rounded="xl"
          >
            <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
              VIP 會員訂閱
            </Text>
            <Text fontSize={{ base: "xs", md: "lg" }}>
              Be the king of the night
            </Text>
            <Flex
              justify="space-between"
              mt={{ base: 4, md: 8 }}
              align="center"
              fontSize={{ base: "xs", md: "md" }}
            >
              <Text>每日贈高額鑽石 | 會員限定折扣｜限定專屬權益</Text>
              <Box role="button" color="#EAC897" onClick={onToggle}>
                {isOpen ? "收合" : "展開"}{" "}
                <FAIcon type={isOpen ? "angle-up" : "angle-down"} />
              </Box>
            </Flex>
          </Box>
          <Collapse in={isOpen}>
            <Membership.Premiums
              mt={-4}
              pt={8}
              pb={3}
              bg="#1B1713"
              borderBottomRadius={12}
            />
          </Collapse>
        </Box>

        {VIP_PLANS.map((plan) => (
          <SubscribePlan
            key={plan.plan}
            onSelect={selectPlan(plan)}
            rate={currency === "TWD" ? 1 : exchangeRates[currency]}
            {...plan}
          />
        ))}
      </VStack>
      <Center py={4}>
        <Link
          textDecoration="underline"
          color="white"
          onClick={openModal}
          fontSize="xs"
        >
          訂閱說明
        </Link>
      </Center>
      <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" fontSize="sm">
          <Box
            bg="#151515"
            mx={8}
            maxW={400}
            color="#D9D9D9"
            p={6}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text
              align="center"
              fontSize="xl"
              borderBottom="2px"
              borderColor="#D9D9D9"
              pb={4}
            >
              訂閱說明
            </Text>
            <VStack align="stretch" gap={4} py={6}>
              <Text>1.付費：使用者確認購買並付款後計入 NightCo 帳號。</Text>
              <Text>
                2.取消訂閱：在目前訂閱週期到期日七個工作天前，在 NightCo
                客服提出申請，並取消自動訂閱功能，不收取任何手續費。
              </Text>
              <Text>
                3.自動訂閱：NightCo 將會在方案到期前 24
                小時內扣費，扣費成功後訂閱週期會自動順一個訂閱週期。
              </Text>
            </VStack>
            <Center>
              <Button onClick={closeModal} variant="themed" px={8}>
                了解
              </Button>
            </Center>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VIPPage;
