import { TP_ENV, TP_ID, TP_KEY } from "@/constants";
import { useEffect, useState } from "react";

const TPDirect = window.TPDirect || null;

const useTappay = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    try {
      if (!ready) {
        TPDirect.setupSDK(TP_ID, TP_KEY, TP_ENV);
        setReady(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [ready]);
  return [TPDirect, ready];
};

export default useTappay;
