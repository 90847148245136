import {
  APPLE_PAY_TEST_PRIME,
  SUPPORTED_PAYMENT_NETWORKS,
  TP_APPLE_PAY_ID,
} from "@/constants";
import useTappay from "@/libs/hooks/tappay";
import {
  Button,
  Center,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import MobileNavigation from "../MobileNavigation";
import FAIcon from "../FAIcon";
import useAPI from "@/libs/hooks/api";
import ApplePayLogo from "@/assets/images/apple-pay.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import gtmInitiateCheckout from "@/libs/gtm-initiate-checkout";

const BASE_PAYMENT_REQUEST = {
  supportedNetworks: SUPPORTED_PAYMENT_NETWORKS,
  supportedMethods: ["apple_pay"],
  shippingOptions: [],
  total: {
    label: "",
    amount: {
      currency: "TWD",
      value: "1",
    },
  },
};

const getPrime = (prime) =>
  process.env.NODE_ENV !== "production" ? APPLE_PAY_TEST_PRIME : prime;

const ApplePayView = ({ active, details, onCancel, onAvailable }) => {
  const [tp, ready] = useTappay();
  const api = useAPI();
  const toast = useToast();
  const navigate = useNavigate();

  const onConfirm = useCallback(() => {
    tp.paymentRequestApi.getPrime(async (response) => {
      const prime = getPrime(response.prime);
      const result = await api.depositApplePay({
        prime,
        price: details.cost,
      });
      if (!result?.data) {
        toast({
          title: "ApplePay 支付失敗，請重新操作",
          status: "error",
        });
        return onCancel();
      }
      const { tradeId, id } = result.data;
      gtmInitiateCheckout(details.cost, "Apple Pay");

      navigate({
        pathname: "/callback/tappay",
        search: createSearchParams({
          rec_trade_id: tradeId,
          order_number: id,
        }).toString(),
      });
    });
  }, [tp, api, details, navigate, toast, onCancel]);

  useEffect(() => {
    if (!ready) return;
    // check apple pay support
    tp.paymentRequestApi.setupApplePay({
      merchantIdentifier: TP_APPLE_PAY_ID,
      countryCode: "TW",
    });
    tp.paymentRequestApi.setupPaymentRequest(
      BASE_PAYMENT_REQUEST,
      ({ browserSupportPaymentRequest: supported }) => {
        if (supported) {
          onAvailable();
        }
      },
    );
  }, [onAvailable, ready, tp]);

  useEffect(() => {
    if (!ready) return;
    if (active) {
      const { cost, discount = 0 } = details;
      const value = (cost - discount).toString();
      const request = {
        ...BASE_PAYMENT_REQUEST,
        displayItems: [
          {
            label: "NightCo",
            amount: {
              currency: "TWD",
              value,
            },
          },
        ],
        total: {
          label: "總金額",
          amount: {
            currency: "TWD",
            value,
          },
        },
      };
      tp.paymentRequestApi.setupPaymentRequest(request);
    }
  }, [active, details, ready, tp]);

  return (
    <Drawer isOpen={active} onClose={onCancel} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        containerProps={{ zIndex: MobileNavigation.Z_INDEX + 100 }}
        borderTopRadius={12}
        bg="white"
      >
        <DrawerBody>
          <Flex justify="flex-end" py={2}>
            <Circle role="button" bg="#eeeeee" size={8} onClick={onCancel}>
              <FAIcon type="xmark" color="#939393" fontSize="lg" />
            </Circle>
          </Flex>
          <Center mb={6}>
            <Button
              onClick={onConfirm}
              height={8}
              bg="black"
              _hover={{ bg: "#030303" }}
            >
              <Flex align="center" justify="center" gap={0.5}>
                <Text color="white" fontSize="sm">
                  Pay with
                </Text>
                <Image width={12} src={ApplePayLogo} />
              </Flex>
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ApplePayView;
