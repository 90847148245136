import { Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AuthLayout from "@/layouts/auth";
import { lazyWithPreload as preload } from "react-lazy-with-preload";
import withContext from "@/context";
import withDebug from "@/libs/hoc/debug";
import withAppBadge from "@/libs/hoc/app-badge";

import ProtectedLayout from "@/layouts/protected";
import UserProfileLayout from "@/layouts/user-profile";
import SemiProtectedLayout from "@/layouts/semi-protected";
import Loading from "@/components/Loading";

import PlaylistPage from "@/pages/playlist";
import DepositPage from "@/pages/payment/deposit";
import VIPPage from "@/pages/payment/vip";
import StreamsPage from "@/pages/streams";
import PersonalInfoPage from "@/pages/profile/personal-info";
import ExplorePage from "@/pages/explore";
import SearchPage from "@/pages/explore/search";
import ChatsPage from "@/pages/chats";
import ChatPage from "@/pages/chat";
import CreatorPage from "@/pages/creator";
import NotificationsPage from "@/pages/notifications";
import FollowedCreators from "@/pages/profile/followed-creators";
import SavedVideos from "@/pages/profile/saved-videos";
import UnlockedVideos from "@/pages/profile/unlocked-videos";
import MembershipPage from "@/pages/profile/membership";
import ErrorPage from "@/pages/error";
import PostRegisterPage from "@/pages/auth/post-register";
import PaymentLayout from "./layouts/payment";
import ExploreLayout from "./layouts/explore";

const AuthPage = preload(() => import("@/pages/auth"));
const ResetPasswordPage = preload(() => import("@/pages/auth/reset-password"));
const ForgetPasswordPage = preload(
  () => import("@/pages/auth/forget-password"),
);
const TappayDepositCallbackPage = preload(
  () => import("@/pages/callback/tappay/deposit"),
);
const TappayVIPCallbackPage = preload(
  () => import("@/pages/callback/tappay/vip"),
);
const TransactionHistoryPage = preload(
  () => import("@/pages/profile/transaction-history"),
);
const ManageAccountPage = preload(
  () => import("@/pages/profile/manage-account"),
);
const ChangePasswordPage = preload(
  () => import("@/pages/profile/change-password"),
);
const BindEmailPage = preload(() => import("@/pages/profile/bind-email"));
const BindPhonePage = preload(() => import("@/pages/profile/bind-phone"));
const NotificationPage = preload(() => import("@/pages/notification"));
const InstallAppPage = preload(() => import("@/pages/install-app"));

const NotFound = preload(() => import("@/pages/not-found"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<SemiProtectedLayout />}>
        <Route index element={<PlaylistPage />} />
        <Route path="/videos/:id" element={<PlaylistPage />} />
        <Route path="/c/:handle" element={<CreatorPage />} />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<AuthPage action="login" />} />
        <Route path="register" element={<AuthPage action="register" />} />
        <Route path="post-register" element={<PostRegisterPage />} />
        <Route path="forget-password" element={<ForgetPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>

      <Route path="/" element={<ProtectedLayout />}>
        <Route path="callback/tappay" element={<TappayDepositCallbackPage />} />
        <Route path="callback/tappay/vip" element={<TappayVIPCallbackPage />} />
        <Route path="explore" element={<ExploreLayout />}>
          <Route index element={<ExplorePage />} />
          <Route path="videos" element={<SearchPage type="videos" />} />
          <Route path="creators" element={<SearchPage type="creators" />} />
        </Route>
        <Route path="/streams/:id?" element={<StreamsPage />} />
        <Route path="chats" element={<ChatsPage />} />
        <Route path="chat/:id" element={<ChatPage />} />
        <Route path="chat/:creatorId/new" element={<ChatPage />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="notifications/:id" element={<NotificationPage />} />
        <Route path="payment" element={<PaymentLayout />}>
          <Route path="deposit" element={<DepositPage />} />
          <Route path="vip" element={<VIPPage />} />
        </Route>
        <Route path="profile">
          <Route path="" element={<UserProfileLayout />}>
            <Route index element={<FollowedCreators />} />
            <Route path="followed-creators" element={<FollowedCreators />} />
            <Route path="unlocked-videos" element={<UnlockedVideos />} />
            <Route path="saved-videos" element={<SavedVideos />} />
          </Route>
          <Route path="membership" element={<MembershipPage />} />
          <Route path="personal-info" element={<PersonalInfoPage />} />
          <Route path="manage-account" element={<ManageAccountPage />} />
          <Route path="bind-email" element={<BindEmailPage />} />
          <Route path="bind-phone" element={<BindPhonePage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />

          <Route
            path="transaction-history"
            element={<TransactionHistoryPage />}
          />
        </Route>
      </Route>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/install-app" element={<InstallAppPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const App = () => (
  <Suspense fallback={<Loading />}>
    <RouterProvider router={router} />
  </Suspense>
);

export default withContext(withDebug(withAppBadge(App)));
