import {
  Box,
  Button,
  Center,
  Circle,
  Collapse,
  Flex,
  Image,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  OrderedList,
  Text,
  useBoolean,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import Ticket from "@/components/Stream/Ticket";
import TicketBought from "@/assets/images/ticket-bought.png";
import OrderConfirm from "../OrderConfirm";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import FAIcon from "../FAIcon";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AuthContext from "@/context/auth";

const Rules = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Center mb={2}>
      <Box
        role="button"
        textDecoration="underline"
        fontSize="xs"
        onClick={onOpen}
      >
        購票說明
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent p={4} fontSize={{ base: "xs", md: "sm" }}>
          <OrderedList>
            <ListItem>
              購買此票卷僅可使用於本場次官方直播，無法使用於未來其他場次之任何直播表演。
            </ListItem>
            <ListItem>
              購買觀影票後可在官方指定之直播時間內進入直播專區。
            </ListItem>
            <ListItem>
              觀影票除直播進行中之原價額度外，亦提供兩種優惠：
            </ListItem>
          </OrderedList>

          <List ml={3}>
            <ListItem>
              a. VIP直播觀影票，具VIP資格的用戶可以 85 折折扣購買。
            </ListItem>
            <ListItem>
              b. 早鳥票優惠，於直播正式開始前，可以 89 折購買早鳥票。
            </ListItem>
          </List>
        </ModalContent>
      </Modal>
    </Center>
  );
};

const TicketWindow = ({
  coverImage,
  tickets,
  ticket,
  startedAt,
  onPurchase,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useBoolean(true);
  const [now, setNow] = useState(Date.now());

  const vip = !!user?.isVip;
  const qualified = !(selected?.type === "vip" && !vip);

  const countdownText = useMemo(() => {
    if (!startedAt || startedAt < now) return "-- 天 -- 小時 -- 分";
    const startTime = dayjs(startedAt);
    const currentTime = dayjs(now);
    const day = startTime.diff(currentTime, "day") + "";
    const hour = (startTime.diff(currentTime, "hour") % 24) + "";
    const min = (startTime.diff(currentTime, "minute") % 60) + "";
    return `${day.padStart(2, "0")} 天 ${hour.padStart(2, "0")} 小時 ${min.padStart(2, "0")} 分`;
  }, [now, startedAt]);

  const onConfirm = useCallback(async () => {
    await onPurchase(selected.type);
    onClose();
  }, [onClose, onPurchase, selected]);

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now), 1000);
    return () => clearInterval(interval);
  }, [startedAt]);

  return (
    <VStack
      bg="#FFC14C"
      height="100%"
      justify="flex-end"
      align="stretch"
      pb={8}
      px={4}
      backgroundImage={coverImage}
      backgroundSize="cover"
    >
      <Box bg="rgba(255,255,255,.8)" rounded="xl" align="stretch" shadow="md">
        <Flex align="center" justify="center" gap={2} mt={6} mb={6}>
          <Text fontSize="sm">直播倒數 {countdownText}</Text>
          <Circle
            bg="white"
            size={6}
            role="button"
            onClick={setExpanded.toggle}
            textAlign="center"
          >
            <FAIcon
              mt={expanded ? 1 : 0}
              type={expanded ? "angle-down" : "angle-up"}
              color="#FFC14C"
            />
          </Circle>
        </Flex>

        <Collapse in={expanded}>
          {ticket ? (
            <>
              <Center>
                <Image src={TicketBought} width={240} />
              </Center>
              <Box
                bg="white"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                p={2}
                mt={4}
                color="#D18F37"
                fontSize="sm"
                textAlign="center"
              >
                <Text>
                  您已購買
                  <Box as="span" fontWeight="bold">
                    @{t(`streams.tickets.${ticket?.type}`)}
                  </Box>
                </Text>
                <Text>期待在直播上與您相遇</Text>
              </Box>
            </>
          ) : (
            <>
              <VStack gap={6} px={6} my={6} align="stretch">
                {tickets.map((ticket) => (
                  <Ticket
                    key={ticket.type}
                    {...ticket}
                    selected={selected?.type === ticket.type}
                    onClick={() => setSelected(ticket)}
                  />
                ))}
              </VStack>
              <Box px={6} pb={4}>
                <Rules />
                <Button
                  bg="#FD2045"
                  _hover={{ bg: "#FD2045" }}
                  rounded="full"
                  color="white"
                  width="100%"
                  isDisabled={!selected || !qualified}
                  onClick={onOpen}
                >
                  {qualified ? "立即購買" : "僅限 VIP 購買"}
                </Button>
              </Box>

              {selected && (
                <OrderConfirm
                  isOpen={isOpen}
                  onClose={onClose}
                  onCancel={onClose}
                  entry={`購買 @${t(`streams.tickets.${selected?.type}`)}`}
                  amount={-selected?.price}
                  onConfirm={onConfirm}
                />
              )}
            </>
          )}
        </Collapse>
      </Box>
    </VStack>
  );
};

export default TicketWindow;
